












import { Component, Vue } from 'nuxt-property-decorator';

import { state, mutations } from '../NavigationState';

import { IconEllipsisH } from '@/icons';

@Component({
  components: {
    IconEllipsisH,
  },
})
export default class SiteHeaderSecondaryNavToggle extends Vue {
  get secondaryNavOpen () {
    return state.secondaryNavOpen;
  }

  toggleSecondaryNav () {
    mutations.toggleSecondaryNav();
  }
}
